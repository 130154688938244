<template>
	<div id="synapse-configurator">
		<ConfiguratorModal
				:modalOpened="modalOpened"
				:partners="partners"
				:closeModal="closeModal"
				:isMobile="isMobile"
				:apiEndpoint="apiEndpoint"
		/>
		<div id="open-synapse-configurator" class="configurator-btn button" @click="openModal">
			Configurer ma solution
		</div>
	</div>
</template>

<script>
import ConfiguratorModal from './components/ConfiguratorModal.vue'
import config from '../config/config.json'

export default {
	name: 'App',
	components: {
		ConfiguratorModal
	},
	data() {
		return {
			modalOpened: false,
			partners: config.partners,
			apiEndpoint: config.apiEndpoint,
			isMobile: false
		}
	},
	mounted() {
		this.setIsMobile()
	},
	methods: {
		openModal() {
			// console.log('Modal opened')
			this.modalOpened = true
		},
		closeModal() {
			// console.log('Modal closed')
			this.modalOpened = false
		},
		setIsMobile() {
			// Mobile detection
			// eslint-disable-next-line max-len
			if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
					// eslint-disable-next-line max-len
					|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
				this.isMobile = true
			}
		}
	}
}
</script>

<style>
#synapse-configurator {
	font-family: "Montserrat", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

.configurator-btn { /* also has .button to inherit css from website*/
	transition: color ease-in-out 0.3s, background ease-in-out 0.3s, border ease-in-out 0.3s;
	background-color: #f61534;
	color: #ffffff !important;
	border-radius: 25px;
	font-size: 0.85em;
	line-height: 1.4em;
	font-weight: 600;
	letter-spacing: 0.02em;
	padding: 1.1em 3.5em;
	display: inline-block;
	margin-bottom: 0.25em;
	text-transform: uppercase;
	vertical-align: middle;
	cursor: pointer;
}

.configurator-btn:hover {
	background-color: #4d5671;
	color: #ffffff !important;
}

.configurator-btn-previous {
	margin-top: 15px;
	display: inline-block;
}

.configurator-btn-previous:hover {
	cursor: pointer;
	text-decoration: underline;
}

.configurator-btn-previous svg {
	height: 16px;
}

h1 {
	font-style: normal;
	font-weight: 700;
	font-size: 36px;
	text-align: center;
	color: #121D42;
	margin: 0;
}

.mobile h1 {
	font-size: 28px;
}

p {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	text-align: center;
	color: #6A7894;
}

.mobile p {
	font-size: 18px;
}

.error-message {
	border-radius: 5px;
	font-size: 18px;
	padding: 15px;
	margin: 15px;
	border: 1px solid #f61534;
	color: #f61534;
	background: #fee1e5;
	text-align: left;
}

.error-message svg {
	height: 18px;
}

.error-message ul {
	margin: 0;
	text-transform: capitalize;
}

</style>
