<template>
	<div class="checkboxes">
		<h1>Sélectionnez vos besoins</h1>
		<p>Ces informations nous permettront de construire votre solution </p>
		<div v-if="displayError" class="error-message">
			<font-awesome-icon icon="fa-solid fa-circle-exclamation"/>&nbsp;
			Cochez au moins une case pour passer à la suite !
		</div>
		<div class="checkboxes-wrapper">
			<template v-for="(service, k) in services">
				<div
					:class="'checkbox ' + (checked.includes(service) ? 'checked' : '')"
					:key="k"
					@click="toggleService(service)">
					<div class="icon">
						<div class="checkmark" v-if="checked.includes(service)">
							<svg
								width="15"
								height="15"
								viewBox="0 0 15 15"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<g clip-path="url(#clip0_353_1403)">
									<path
										d="M0.536133 9.16064L3.46113 12.9214C3.55983 13.0496 3.6863 13.1538 3.83104 13.2262C3.97578 13.2986 4.13504 13.3372 4.29685 13.3392C4.45605 13.3411 4.61367 13.3074 4.75823 13.2407C4.9028 13.174 5.03067 13.0758 5.13256 12.9535L14.4647 1.66064"
										stroke="#57A6FF"
										stroke-width="2"
										stroke-linecap="round"
										stroke-linejoin="round"/>
								</g>
								<defs>
									<clipPath id="clip0_353_1403">
										<rect width="15" height="15" fill="white"/>
									</clipPath>
								</defs>
							</svg>
						</div>
					</div>
					<div class="name">{{ service }}</div>
				</div>
			</template>
		</div>
		<div class="configurator-btn" @click="checkAndNext">Continuer</div>
	</div>
</template>

<script>
export default {
	name: 'Step1Checkboxes',
	props: {
		nextStep: Function,
		toggleService: Function,
		services: Array,
		checked: Array
	},
	data() {
		return {
			displayError: false
		}
	},
	methods: {
		checkAndNext() {
			// check if everything ok
			if (this.checked.length) {
				this.displayError = false
				this.nextStep()
			} else {
				// else display message
				this.displayError = true
			}
		}
	}
}
</script>

<style scoped>
.checkboxes {
	padding-bottom: 30px;
}

.checkboxes-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 45px 0;
}

.mobile .checkboxes-wrapper {
	padding: 30px 15px;
}

.checkbox {
	position: relative;
	/*width: calc(50% - 45px);*/
	width: calc(33% - 45px);
	background: #FFFFFF;
	border-radius: 6px;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	color: #000000;
	min-height: 30px;
	display: flex;
	flex-wrap: wrap;
	align-content: center;
	justify-content: flex-start;
	align-items: center;
	padding: 10px 15px;
	margin-bottom: 15px;
	transition: background 0.3s ease; /* do not include margin */
}

.mobile .checkbox {
	width: 100%;
}

.checkbox:hover {
	cursor: pointer;
	background: #F4F4F4;
}

.checked {
	border: 2px solid #57A6FF;
	margin-bottom: 11px; /* compensate border added*/
	padding-left: 13px; /* compensate border added*/
}

.icon {
	height: 20px;
	width: 20px;
	background: #E6E6E6;
	border-radius: 10px;
	margin-right: 15px;
}

.checkmark {
	padding-left: 5px;
}

.name {
	max-width: calc(100% - 35px);
	text-align: left;
}

</style>
