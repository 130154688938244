<template>
	<div :class="'background ' + (modalOpened ? 'open' : 'close')">
		<div :class="'modal ' + (isMobile ? 'mobile' : '')">
			<div id="close-btn" @click="closeModal">
				<font-awesome-icon icon="fa-solid fa-xmark"/>
			</div>
			<div class="filigrane"></div>
			<div class="modal-content">
				<template v-if="step === 1">
					<Checkboxes
						:services="flattenAndUniqueServices()"
						:checked="clientConfig.services"
						:nextStep="nextStep"
						:toggleService="toggleService"
					/>
				</template>
				<template v-if="step === 2">
					<ContactInfo
						:contactInfo="clientConfig.contactInfo"
						:nextStep="nextStep"
						:previousStep="previousStep"
						:isMobile="isMobile"
					/>
				</template>
				<template v-if="step === 3">
					<Results
						:sendClientConfig="sendClientConfig"
						:filteredPartners="filteredPartners()"
						:checkedServices="clientConfig.services"
						:fetched="fetched"
						:error="error"
						:closeModal="closeModal"
						:restart="restart"
					/>
				</template>
			</div>
		</div>
	</div>
</template>

<script>

import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import {
	faGlobe, faLeftLong, faPhone, faRotateLeft, faXmark, faCircleExclamation
} from '@fortawesome/free-solid-svg-icons'
import Checkboxes from './Step1Checkboxes.vue'
import ContactInfo from './Step2ContactInfo.vue'
import Results from './Step3Results.vue'

/* --- config for FontAwesome --- */
Vue.component('font-awesome-icon', FontAwesomeIcon) // add font awesome icon component
Vue.config.productionTip = false
library.add(faFacebook)
library.add(faLinkedin)
library.add(faGlobe)
library.add(faPhone)
library.add(faLeftLong)
library.add(faRotateLeft)
library.add(faXmark)
library.add(faCircleExclamation)
/* --- end config for FontAwesome --- */

export default {
	name: 'ConfiguratorModal',
	components: {
		Checkboxes,
		ContactInfo,
		Results
	},
	props: {
		partners: Array,
		modalOpened: Boolean,
		isMobile: Boolean,
		closeModal: Function,
		apiEndpoint: String
	},
	data() {
		return {
			clientConfig: {
				services: [],
				contactInfo: {
					nom: '',
					prenom: '',
					email: '',
					tel: '',
					etablissement: '',
					nbChambres: 30,
					nbEtoiles: 2,
					rgpd: false
				}
			},
			step: 1, // 1 : Checkboxes - 2 : Contact info - 3 : Results
			fetched: false,
			error: false
		}
	},
	methods: {
		nextStep() {
			this.step += 1
		},
		previousStep() {
			this.step -= 1
		},
		toggleService(service) {
			if (this.clientConfig.services.includes(service)) {
				// remove a service
				this.clientConfig.services = this.clientConfig.services.filter(s => s !== service)
			} else {
				// add a service
				this.clientConfig.services = this.clientConfig.services.concat(service)
			}
		},
		flattenAndUniqueServices() {
			return [...new Set(this.partners.map(p => p.services)
				.flat())]
		},
		filteredPartners() {
			return this.partners
				.filter(p => p.services.some(s => this.clientConfig.services.includes(s)))
				.sort((a, b) => {
					// display the one with the most matches first
					const countA = a.services.reduce((acc, service) => (this.clientConfig.services.includes(service) ? acc + 1 : acc), 0)
					const countB = b.services.reduce((acc, service) => (this.clientConfig.services.includes(service) ? acc + 1 : acc), 0)
					return countB - countA
				})
		},
		sendClientConfig() {
			const payload = {
				clientConfig: this.clientConfig,
				matchingPartners: this.filteredPartners()
					.map(p => ({
						name: p.name,
						notifEmail: p.notifEmail
					}))
			}
			const isProduction = process.env.NODE_ENV === 'production'
			if (!isProduction) {
				console.info('payload', JSON.stringify(payload))
			}
			const endPoint = isProduction ? this.apiEndpoint : 'http://localhost:4000'
			const route = '/api/configurator'

			// POST
			const xhr = new XMLHttpRequest()
			xhr.open('POST', endPoint + route, false) // synchronous request
			xhr.setRequestHeader('Content-Type', 'application/json')
			try {
				xhr.send(JSON.stringify(payload))
				if (xhr.status !== 201) {
					this.error = true
					this.fetched = true // still display results
					console.error(`Error ${xhr.status}: ${xhr.statusText}`)
				} else {
					this.fetched = true
				}
			} catch (err) { // instead of onerror
				console.error('Request failed', err.toString())
				this.error = true
				this.fetched = true // still display results
			}
		},
		restart() {
			this.clientConfig.services = []
			this.step = 1
			this.error = false
			this.fetched = false
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.background {
	z-index: 9001;
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	transition: all 0.3s ease;
}

.modal {
	position: relative;
	width: 1150px; /*todo responsive*/
	height: 700px; /*todo responsive*/
	background: #DBECFF;
	border-radius: 15px;
	padding: 45px;
	overflow-x: hidden;
	overflow-y: auto;
}

.mobile {
	width: 100vw;
	height: 100vh;
	padding: 0;
}

.close {
	opacity: 0;
	visibility: collapse;
}

.open {
	opacity: 1;
	visibility: visible;
}

#close-btn {
	position: absolute;
	top: 15px;
	right: 15px;
	font-size: 2em;
	width: 40px;
	height: 40px;
	text-align: center;
	vertical-align: middle;
	transition: all 0.2s ease;
	z-index: 2;
}

#close-btn:hover {
	cursor: pointer;
	top: 10px;
	font-size: 2.5em;
}

#close-btn svg {
	width: 40px;
	height: 40px;
}

.modal-content {
	z-index: 1;
	position: absolute;
	height: calc(100% - 90px);
	width: calc(100% - 90px);
}

.mobile .modal-content {
	width: 100%;
	height: initial;
	top: 60px;
}

.filigrane {
	z-index: 0;
	background-image: url('../assets/synapse010.png');
	position: absolute;
	height: calc(100% - 90px);
	width: calc(100% - 90px);
	background-repeat: no-repeat;
	/*opacity: 0.2;*/
	background-position: bottom right;
	background-size: contain;
}

.mobile .filigrane {
	width: 50vw;
	height: 100%;
	bottom: 0;
	right: 0;
}

</style>
