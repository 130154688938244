<template>
	<div class="contact-info">
		<h1>A propos de votre établissement </h1>
		<p>Ces informations nous permettront de construire votre solution</p>
		<div v-if="displayError" class="error-message">
			<font-awesome-icon icon="fa-solid fa-circle-exclamation"/>&nbsp;
			Au moins un champ n'est pas saisi correctement
			<ul>
				<li v-for="champ in errorInputs" :key="champ">{{ champ }}</li>
			</ul>
		</div>
		<div class="info-wrapper">
			<div class="form-third">
				<div class="label">Nom de l’établissement*</div>
				<input
					:class="errorInputs.includes('etablissement') ? 'error-input' : ''"
					v-model="contactInfo.etablissement" required/>
			</div>
			<div class="form-third">
				<div class="label">Nombre de chambres</div>
				<div class="circle" @click="removeRoom">&minus;</div>
				<div class="value">{{ contactInfo.nbChambres }}</div>
				<div class="circle" @click="addRoom">&plus;</div>
			</div>
			<div class="form-third">
				<div class="label">Nombre d’étoiles</div>
				<div class="circle" @click="removeEtoile">&minus;</div>
				<div class="value">{{ contactInfo.nbEtoiles }}</div>
				<div class="circle" @click="addEtoile">&plus;</div>
			</div>
			<div class="form-half">
				<div class="label">Nom*</div>
				<input
					:class="errorInputs.includes('nom') ? 'error-input' : ''"
					v-model="contactInfo.nom" required/>
			</div>
			<div class="form-half">
				<div class="label">Prénom</div>
				<input
					:class="errorInputs.includes('prenom') ? 'error-input' : ''"
					v-model="contactInfo.prenom">
			</div>
			<div class="form-half">
				<div class="label">Email*</div>
				<input
					type="email"
					:class="errorInputs.includes('email') ? 'error-input' : ''"
					v-model="contactInfo.email" required/>
			</div>
			<div class="form-half">
				<div class="label">Téléphone*</div>
				<input
					type="tel"
					:class="errorInputs.includes('tel') ? 'error-input' : ''"
					v-model="contactInfo.tel" required/>
			</div>
			<div class="form-full">
				<div class="label">RGPD*</div>
				<div class="rgpd-wrapper">
					<input
						:class="errorInputs.includes('rgpd') ? 'error-input' : ''"
						type="checkbox" v-model="contactInfo.rgpd" required/>
					<div class="rgpd-text">
						En remplissant ce formulaire, vous consentez à fournir ces informations à Synapse. Vous
						bénéficiez d'un droit d'accès, de modification, de rectification et de suppression de vos
						données
						personnelles. Vous bénéficiez du droit de vous inscrire sur la liste d'opposition au démarchage
						téléphonique. Pour les exercer, merci de nous contacter.
					</div>
				</div>
			</div>
		</div>
		<div class="configurator-btn" @click="checkAndNext">Valider</div>
		<div class="configurator-btn-previous" @click="previousStep">
			<font-awesome-icon icon="fa-solid fa-left-long"/>&nbsp;
			Précédent
		</div>
	</div>
</template>

<script>
export default {
	name: 'Step2ContactInfo',
	props: {
		nextStep: Function,
		previousStep: Function,
		contactInfo: Object,
		isMobile: Boolean
	},
	data() {
		return {
			errorInputs: [],
			displayError: false
		}
	},
	methods: {
		checkAndNext() {
			// check if everything ok
			const {
				etablissement,
				nbChambres,
				nom,
				email,
				tel,
				rgpd
			} = this.contactInfo

			// todo regex mail and phone
			const errorInputs = []
			const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
			const phoneRegex = /^(0|\+33)[1-9]([ .-]?\d{2}){4}$/g

			if (!etablissement) {
				errorInputs.push('etablissement')
			}
			if (!nbChambres) {
				errorInputs.push('nbChambres')
			}
			if (!nom) {
				errorInputs.push('nom')
			}
			if (!email || !email.match(emailRegex)) {
				errorInputs.push('email')
			}
			if (!tel || !tel.match(phoneRegex)) {
				errorInputs.push('tel')
			}
			if (!rgpd) {
				errorInputs.push('rgpd')
			}

			if (!errorInputs.length) {
				this.errorInputs = []
				this.displayError = false
				this.nextStep()
			} else {
				this.errorInputs = errorInputs
				this.displayError = true
				// scroll top if mobile
				if (this.isMobile) {
					document.getElementsByClassName('modal')[0].scrollTo({top: 0, behavior: 'smooth'})
				}
			}
		},
		addRoom() {
			this.contactInfo.nbChambres += 5
		},
		removeRoom() {
			if (this.contactInfo.nbChambres > 1) {
				this.contactInfo.nbChambres -= 5
			}
		},
		addEtoile() {
			this.contactInfo.nbEtoiles += 1
		},
		removeEtoile() {
			if (this.contactInfo.nbEtoiles > 0) {
				this.contactInfo.nbEtoiles -= 1
			}
		}
	}
}
</script>

<style scoped>
.contact-info {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: nowrap;
	padding-bottom: 30px;
}

.label {
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	width: 100%;
	text-align: left;
	margin-bottom: 10px;
	color: #121D42;
}

.form-third {
	width: calc(33% - 30px);
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;
	margin-bottom: 30px;
}

.mobile .form-third {
	width: 100%;
}

.form-half {
	width: calc(50% - 30px);
	margin-bottom: 30px;
}

.mobile .form-half {
	width: 100%;
}

.form-full {
	width: 100%;
}

input {
	height: 30px;
	background: #FFFFFF;
	border-radius: 6px;
	width: calc(100% - 30px);
	border: none;
	padding: 15px;
	font-size: 16px;
	font-family: "Montserrat", sans-serif;
	margin: 0;
}

input:focus {
	border: 1px solid #57A6FF;
}

input.error-input {
	border: 1px solid #f61534;
}

input[type="checkbox"] {
	position: relative;
	-webkit-appearance: none;
	width: 30px;
	margin-right: 15px;
	border-radius: 50%;
}

/*input[type="checkbox"].error-input {*/
/*	background-color: #f61534;*/
/*}*/

input[type="checkbox"]:checked {
	background-color: #E6E6E6;
}

input[type="checkbox"]:checked:before {
	content: '✔';
	color: #57A6FF;
	top: -9px;
	left: 6px;
	position: absolute;
	font-size: 30px;
}

.info-wrapper {
	padding: 45px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.mobile .info-wrapper {
	padding: 30px;
}

.circle {
	background: #4357aa;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	padding: 15px;
	text-align: center;
	color: #ffffff;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	transition: background 0.3s ease;
}

.circle:hover {
	cursor: pointer;
	background: #121D42;
}

.value {
	color: #000000;
	font-size: 45px;
	font-weight: 700;
	text-align: center;
	min-width: 100px;
	line-height: 60px;
}

.rgpd-wrapper {
	display: flex;
	align-items: flex-start;
}

.mobile .rgpd-wrapper {

}

.rgpd-text {
	text-align: left;
	font-size: 12px;
	line-height: 16px;
}

</style>
