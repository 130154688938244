<template>
	<div class="results">
		<h1>La solution Synapse</h1>
		<p>
			Merci pour ces informations, voici les entreprises prêtes à travailler avec vous.
			<br/>
			<span v-if="!error"> Vous serez contacté par celles-ci pour mettre en place votre projet.</span>
			<span v-else> Prenez note de cette liste et contactez-les !</span>
		</p>
		<div class="spinner" v-if="!fetched"></div>
		<div class="partners" v-if="fetched">
			<template v-for="(partner, kpart) in filteredPartners">
				<div class="partner" :key="kpart">
					<a
						class="logo"
						:href="partner.website"
						title="Voir le site web"
						target="_blank"
						rel="noreferrer noopener"
					>
						<img :src="partner.logoUrl" :alt="'Logo ' + partner.name "/>
					</a>
					<div class="name">{{ partner.name }}</div>
					<div class="contact">
						<div class="website" v-if="partner.website">
							<a :href="partner.website" title="Site web" target="_blank" rel="noreferrer noopener">
								<font-awesome-icon icon="fa-solid fa-globe"/>
							</a>
						</div>
						<div class="tel" v-if="partner.tel">
							<a :href="'tel:' + partner.tel" :title="partner.tel">
								<font-awesome-icon icon="fa-solid fa-phone"/>
							</a>
						</div>
						<div class="facebook" v-if="partner.facebook">
							<a :href="partner.facebook" title="Facebook" target="_blank" rel="noreferrer noopener">
								<font-awesome-icon icon="fa-brands fa-facebook"/>
							</a>
						</div>
						<div class="linkedin" v-if="partner.linkedin">
							<a :href="partner.linkedin" title="LinkedIn" target="_blank" rel="noreferrer noopener">
								<font-awesome-icon icon="fa-brands fa-linkedin"/>
							</a>
						</div>
					</div>
					<div class="services">
						<template v-for="(service, keyserv) in partner.services">
							<div
								:class="'service ' + (checkedServices.includes(service) ? 'checked' : '')"
								:key="kpart + '-' + keyserv"
							>
								{{ service }}
							</div>
						</template>
					</div>
				</div>
			</template>
		</div>
		<div class="configurator-btn" v-if="fetched" @click="closeModal">Fermer</div>
		<div class="configurator-btn-previous" v-if="fetched" @click="restart">
			<font-awesome-icon icon="fa-solid fa-rotate-left"/>&nbsp;
			Recommencer
		</div>
	</div>
</template>

<script>
export default {
	name: 'Step3Results',
	props: {
		filteredPartners: Array,
		checkedServices: Array,
		sendClientConfig: Function,
		closeModal: Function,
		restart: Function,
		fetched: Boolean,
		error: Boolean
	},
	mounted() {
		// send data
		setTimeout(() => {
			this.sendClientConfig()
		}, 100)
		// on callback remove spinner and display results
	},
	methods: {}
}
</script>

<style scoped>
.results {
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: nowrap;
	padding: 0 15px;
	margin-bottom: 30px;
}

.partners {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
	margin-bottom: 30px;
}

.partner {
	padding: 15px;
	background: #FFFFFF;
	border-radius: 6px;
	/*width: calc(33.333% - 60px);*/
	width: calc(50% - 60px);
	margin: 15px;
	transition: all 0.3s ease;
}

.mobile .partner {
	width: 100%;
}

.partner:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.logo {
	display: block;
	max-width: 200px;
	min-height: 70px;
	margin: auto;
}

.logo img {
	width: 100%;
}

.name {
	font-size: 20px;
	font-weight: 700;
	margin: 15px 0;
}

.contact {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	font-size: 24px;
}

.contact a {
	color: inherit;
}

.contact a:hover {
	color: #4d5671;
}

.website, .tel, .facebook, .linkedin {
	color: #121D42;
	text-decoration: none;
	padding: 5px 10px;
}

svg {
	fill: #121D42;
	height: 1em;
}

.services {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.service {
	background: #e9f3ff;
	border: 1px solid #57A6FF;
	border-radius: 5px;
	font-size: 12px;
	line-height: 16px;
	color: #57A6FF;
	padding: 5px 15px;
	margin: 5px;
}

.checked {
	border-color: #1aaa55;
	color: #1aaa55;
	background: #d6f5e4;
	font-weight: 700;
}

.spinner {
	width: 30px;
	height: 30px;
	margin: 150px auto 0;
	border: 5px solid #6A7894;
	border-top-color: #121D42;
	border-radius: 50%;
	background-color: transparent;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
</style>
