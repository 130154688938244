// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/synapse010.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".background[data-v-d06e5aa8]{z-index:9001;position:absolute;top:0;left:0;height:100vh;width:100vw;background:rgba(0,0,0,.7);display:flex;justify-content:center;align-content:center;flex-wrap:wrap;transition:all .3s ease}.modal[data-v-d06e5aa8]{position:relative;width:1150px;height:700px;background:#dbecff;border-radius:15px;padding:45px;overflow-x:hidden;overflow-y:auto}.mobile[data-v-d06e5aa8]{width:100vw;height:100vh;padding:0}.close[data-v-d06e5aa8]{opacity:0;visibility:collapse}.open[data-v-d06e5aa8]{opacity:1;visibility:visible}#close-btn[data-v-d06e5aa8]{position:absolute;top:15px;right:15px;font-size:2em;width:40px;height:40px;text-align:center;vertical-align:middle;transition:all .2s ease;z-index:2}#close-btn[data-v-d06e5aa8]:hover{cursor:pointer;top:10px;font-size:2.5em}#close-btn svg[data-v-d06e5aa8]{width:40px;height:40px}.modal-content[data-v-d06e5aa8]{z-index:1;position:absolute;height:calc(100% - 90px);width:calc(100% - 90px)}.mobile .modal-content[data-v-d06e5aa8]{width:100%;height:auto;top:60px}.filigrane[data-v-d06e5aa8]{z-index:0;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");position:absolute;height:calc(100% - 90px);width:calc(100% - 90px);background-repeat:no-repeat;background-position:100% 100%;background-size:contain}.mobile .filigrane[data-v-d06e5aa8]{width:50vw;height:100%;bottom:0;right:0}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
